import { useRef, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";


import Header from './components/Header';

import Home from './pages/Home';
import Info from './pages/Info';
import Werk from './pages/Werk';
import Contact from './pages/Contact';

import './css/style.css';

function App() {
  const location = useLocation();
  const appRef = useRef(null)

  useEffect(() => {
    appRef.current.scrollTo(0, 0);
  }, [location]);

  return (
    <div ref={appRef} className="App">
      <Header />
      <AnimatePresence initial={false} mode='wait'>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="info" element={<Info />} />
          <Route exact path="werk" element={<Werk />} />
          <Route exact path="contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
