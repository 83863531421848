const data = {
  'pages': {

    /* Home pagina */

    'home': {
      'home': {
        'text': {
          'title': 'Heritage',
          'subtitle': ['Ontzorgt'],
          'paragraph': ['Heritage helpt u bij het transport van uw waardevolle spullen, geeft advies en doet uw inboedelmanagement. Hier vind u meer informatie over onze diensten en kunt u terecht met al uw vragen. Misschien kan Heritage ook u helpen met ontzorgen!'],
        }
      }
    },

    /* "Over mij" pagina */

    'info': {
      'over': {
        'text': {
          'title': 'Over mij',
          'subtitle': ['Hallo, ','ik zal me even voorstellen'],
          'paragraph': ['Mijn naam is Thijs van der Feltz, geboren in 1984. In 2006 haalde ik mijn diploma op de Middelbare Hotelschool in Wageningen en in 2010 mijn bachelor International Hospitality Management op de Robert Gordon University in Aberdeen, Schotland. Mijn hart bleek echter te liggen bij kunst, antiek en het veilinghuis. Mijn vader werkte daar als veilingmedewerker en als klein mannetje rende ik daar al rond.','Tijdens mijn middelbare schoolperiode kon ik bijverdienen op de veiling. Er waren tijdens de veilingen altijd extra handen nodig. Zo kwam van het een het ander. Na mijn studie in Schotland ben ik full-time bij datzelfde veilinghuis gaan werken. Ik was uiteindelijk verantwoordelijk voor de hele interne logistiek en het transport. In 2016 heb ik de overstap gemaakt naar een bedrijf dat zich specialiseert in inboedelmanagement en de afwikkeling van nalatenschappen.','In 2022 ben ik Heritage gestart en nu help ik u graag bij nalatenschappen en met advies bij verkoop van antiek, kunst en waardevolle inboedel.'],
        },
        'images': ['thijs.jpeg']
      }
    },

    /* "Mijn werk" pagina */

    'werk': {
      'transport': {
        'text': {
          'title': 'Transport',
          'subtitle': ['Uw spullen ','in vertrouwde handen'],
          'paragraph': ['Moeten uw veilingaankopen van A naar B of wilt u uw collectie intern verplaatsen? Dit soort uitdagingen kunnen op voorhand veel onzekerheden met zich meebrengen en vragen bij oproepen. Gelukkig heb ik een breed netwerk en kennis, inzicht en ervaring op dit gebied. Met materiaal, mankracht en de benodigde vervoersmiddelen die ik tot mijn beschikking heb kan ik u goed ondersteunen tijdens deze processen.'],
        }
      },
      'advies': {
        'text': {
          'title': 'Bemiddeling & advies',
          'subtitle': ['Vragen? ','ik help u'],
          'paragraph': ['Heeft u een item dat u wilt verkopen of waarvan u nog niet goed weet wat het is en of het een bepaalde waarde vertegenwoordigd? Ik help u graag bij het beantwoorden van deze vragen. U weet maar nooit.','Het verkopen van Kunst en Antiek, Design of items uit een nalatenschap kan een gevoelige en fysiek zware aangelegenheid zijn. Neem gerust contact met mij op met vragen over wat iets is, wat het waard kan zijn, waar spullen naartoe moeten en hoe je deze spullen vervoert. Geen paniek, het komt goed!'],
        },
        'images': ['8.jpg','6.jpg','2.jpg'],
    },
    'inboedelmanagement': {
      'text': {
        'title': 'Inboedelmanagement',
        'subtitle': ['Met ','Kennis en inzicht'],
        'paragraph': ['Door de jaren heen heb ik veel ervaring opgedaan met het transport, handel en wandel van goederen en het nalatenschapsbeheer. Inmiddels beschik ik over een breed netwerk, kennis en inzicht. Door materiaal, mankracht en de benodigde vervoersmiddelen die ik tot mijn beschikking heb kan ik u goed ondersteunen tijdens dit proces. Dus bent u een dierbare verloren en/of executeur testamentair van een nalatenschap, dan zijn mijn diensten wellicht interessant voor u.'],
      }
    },
  },
    /* Contact pagina */

    'contact': {
      'subheader': {
          'title': 'Contact',
          'subtitle': ['Ik kijk uit naar ','uw bericht'],
          'image': 'Arnhem-kaart.png',
      }
    },


  }
}

export { data as default }
