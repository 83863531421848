import { motion } from "framer-motion";

import Subheader from '../components/Subheader';
import Contactcard from '../components/Contactcard'
import Footer from '../components/Footer';

export default function Contact() {
  return (
    <motion.div
    className="page-container"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: .4, ease: [0.43, 0.13, 0.23, 0.96]}}
    >

      <Subheader page="contact"/>

      <div className="content-wrapper">
        <p className="content-paragraph">Heeft u vragen over mijn werk en wat ik voor u kan betekenen? Neem gerust contact met me op! Ik ga graag met u in gesprek over uw wensen, uitdagingen en klussen en beantwoord met liefde al uw vragen.</p>
        <Contactcard />
      </div>


      <Footer />

    </motion.div>
  )
}
