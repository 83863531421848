export default function ContentText(props) {
  const text = props.text;

  var st = text.subtitle;
  var p = text.paragraph;

  st = st.length > 1 ? st.reduce((result, item) => <>{result}<span>&nbsp;{item}&nbsp;</span></>) : st.map(result => (<span key="">&nbsp;{result}&nbsp;</span>));
  p = p.length > 1 ? p.reduce((result, item) => <>{result}<br/><br/>{item}</>) : p;

  return (
    <div className="content-column">
      {text.title && <h2 className="content-title">{text.title}</h2>}
      {text.subtitle && <h2 className="content-subtitle">{st}</h2>}
      {text.paragraph && <p className="content-paragraph">{p}</p>}
    </div>
  )
}
