export default function Contactcard() {
  return(
    <div className="contactcard">
      <div className="info">
        <div className="column">
          <p>Naam:</p>
          <p>E-mail:</p>
          <p>Telefoon:</p>
        </div>
        <div className="column">
          <p>Thijs van der Feltz</p>
          <p><a href="mailto:info@heritage.nl">info@heritage.nl</a></p>
          <p><a href="tel:+31646296823">+31 6 46 39 68 23</a></p>
        </div>
      </div>
       {/*}<a className="export-button" href="../Thijs van der  Feltz.vcf" download>Save contact</a>*/}
    </div>
  )
}
