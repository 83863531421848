import { Link } from "react-router-dom";

import '../css/footer.css';

export default function Footer() {
  return (
    <footer>

      <div className="col col-1">
        <h2>Contact</h2>
        <a href="mailto:thijs@heritage-ontzorgt.nl">thijs@heritage-ontzorgt.nl</a>
        <a href="tel:+31646396823">+31 6 46 39 68 23</a>
      </div>

      <div className="col col-2">
        <h2>Blijf op de hoogte</h2>
        <a href="https://www.instagram.com/">Instagram</a>
      </div>

      <div className="col col-3">
        <h2>Lees verder</h2>
        <Link to="/info">Over mij</Link>
        <Link to="/werk">Mijn werk</Link>
      </div>

      <img src="/img/logo.png" alt="Heritage logo" className="footer-logo" />
      
    </footer>
  )
}
