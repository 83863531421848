export default function Icons() {
  return (
    <div className="icons-block">
      <div className="icons-wrapper">

        <div className="icon">
          <img src='/img/transport-icon.png' alt="transport"></img>
          <p>Transport kunst en antiek </p>
        </div>

        <div className="icon">
          <img src='/img/bemiddeling-advies-icon.png' alt="bemiddeling en advies"></img>
          <p>Bemiddeling en advies</p>
        </div>

        <div className="icon">
          <img src='../img/inboedelmanagement-icon.png' alt="inboedelmanagement"></img>
          <p>Inboedelmanagement</p>
        </div>

      </div>
    </div>
  )
}
