import contentData from '../content-data';

export default function Subheader(props) {
  const page = contentData.pages[props.page];
  const subheader = page.subheader;

  var st = subheader.subtitle;
  st = st.length > 1 ? st.reduce((result, item) => <>{result}<span>&nbsp;{item}&nbsp;</span></>) : st.map(result => (<span key="">&nbsp;{result}&nbsp;</span>));

  return (
    <div className="subheader">

      <div className="title-block">
        {subheader.title && <h2 className="content-title">{subheader.title}</h2>}
        {subheader.subtitle && <h2 className="content-subtitle">{st}</h2>}
      </div>

      <img src={'/img/'+subheader.image} alt=""></img>

    </div>
  )
}
