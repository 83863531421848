import { Link } from "react-router-dom";

import Menu from '../components/Menu';

import '../css/header.css';

export default function Header() {

  function closeMenu() {
    document.getElementById("hamburger-toggle").checked = false;
  }

  return (
    <header>

      <div className="header-container">

      <Link to="/">
        <img src="/img/logo.png" alt="Heritage logo" className="header-logo" />
      </Link>

      <Menu />

      <div className="hamburger-container">
        <input id="hamburger-toggle" type="checkbox"/>
        <label htmlFor="hamburger-toggle" id="hamburger-hitmarker"></label>
        <div className="hamburger"></div>

        <div className="fullscreen-menu">
          {/*<div className="image">
            <a><p>Website: Rinus van Hofweegen</p></a>
            <div className="menu-image"></div>
          </div>*/}

          <div className="sidebar">
            <img src="/img/logo.png" alt="Heritage logo" className="sidebar-logo" />
            <span className="line-break"/>
            <div className="sidebar-menu">
              <Link to="/" onClick={closeMenu}>Voorpagina</Link>
              <Link to="/info" onClick={closeMenu}>Over mij</Link>
              <Link to="/werk" onClick={closeMenu}>Mijn werk</Link>
              <Link to="/contact" onClick={closeMenu}>Contact</Link>
            </div>

            <div className="menu-info">
              <div className="column-container">

                <div className="menu-column">
                  <h2>Herritage<br />Dienstverlening &#169;</h2>
                  <span className="logo-line"/>
                </div>

                <div className="menu-column">
                  <a href="mailto:thijs@heritage-ontzorgt.nl">thijs@heritage-ontzorgt.nl</a>
                  <a href="tel:+31646396823">+31 6 46 39 68 23</a>
                </div>

              </div>
            </div>

          </div>
      </div>

      </div>

      </div>

    </header>
  )
}
