import { useEffect } from 'react';
import { motion } from "framer-motion";

import Titlescreen from '../components/Titlescreen';
import Content from '../components/Content';
import Icons from '../components/Icons';
import Footer from '../components/Footer';

export default function Home() {
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return(
    <motion.div
    className="page-container"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: .4, ease: [0.43, 0.13, 0.23, 0.96]}}
    >

      <Titlescreen />

      <Content page="home" block="home" />
      <Icons />
      <Content page="werk" block="transport" />
      <Content page="werk" block="advies" />
      <Content page="werk" block="inboedelmanagement" />

      <Footer />

    </motion.div>
  )
}
