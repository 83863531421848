import { useEffect } from 'react';
import { motion } from "framer-motion";

import Content from '../components/Content';
import Footer from '../components/Footer';

export default function Info() {
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
    className="page-container"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: .4, ease: [0.43, 0.13, 0.23, 0.96]}}
    >

      <Content page="info" block="over" />
      <Footer />

    </motion.div>
  )
}
