import { Link } from "react-router-dom";

export default function Menu() {
  return (
    <ul className="menu">
      <li><Link to="/">Voorpagina</Link></li>
      <li><Link to="info">Over mij</Link></li>
      <li><Link to="werk">Mijn werk</Link></li>
      <li><Link to="contact">Contact</Link></li>
    </ul>
  )
}
