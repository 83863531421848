import { motion } from "framer-motion";

export default function Titlescreen() {
  const images = ['1.jpg','2.jpg','3.jpg','4.jpg','5.jpg','6.jpg','7.jpg','8.jpg'];

  const sets = images.reduce(function (sets, img, index) {
    return (index % 2 === 0 ? sets.push([img])
      : sets[sets.length-1].push(img)) && sets;
  }, []);

  return(
    <div className="titlescreen">
      <div className="titlescreen-title">

        <motion.h2
        initial={{ opacity: 0, transform: "translateX(-40%)" }}
        animate={{ opacity: 1 , transform: "translateX(0%)" }}
        transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}>
        In vertrouwde
        </motion.h2>

        <motion.h2
        initial={{ transform: "translateX(40vmax)", opacity: 0 }}
        animate={{ transform: "translateX(9vmax)", opacity: 1 }}
        transition={{ duration: 1.2, ease: [0.43, 0.13, 0.23, 0.96] }}>
        handen
        </motion.h2>

      </div>

      <div className="titlescreen-images">
        {sets.map((set, i) => (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: .5, delay: Math.floor(Math.random() * 7) * 0.03 }} className="set" key={i}>
            { set.map((image, i) => (<motion.img src={'/img/'+image} alt="" key={i}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96], delay: i / 2 + Math.floor(Math.random() * 4) * 0.3 }}></motion.img>)) }
          </motion.div>
        ))}
      </div>
    </div>
  )
}
