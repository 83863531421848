import ContentText from './ContentText';

import contentData from '../content-data';

import '../css/content.css';

export default function Content({ page, block }) {
  const pageData = contentData.pages[page];
  const content = pageData[block];

  if (content === undefined) {
    console.log('missing content for "'+page+'"');
    return (null);
  }

  const images = content.images;
  const text = content.text;

  return (
    <div className="content-wrapper">
      <div className="content-block">

        {images && (images.length > 2)
            ? <ContentImages {...{ images }} />
            : images && <ContentImage image={images}/>}
        {text && <ContentText text={text}/>}

      </div>
    </div>
  )
}

function ContentImage({ image }) {
  return (
    <div className="content-column">
      <div className="content-images">
        <img src={'/img/'+image} />
      </div>
    </div>
  )
}

function ContentImages({ images }) {
  return (
    <div className="content-column">
      <div className="content-images">
        {images && images.map((image, i) => (<img src={'/img/'+image} className={'pos'+i} alt="" key={i}></img>))}
      </div>
    </div>
  )
}
